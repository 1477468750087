import { css } from '@emotion/react'
import React, { ReactNode, useState } from 'react'
import { setHeight0, setHeightAuto } from '../../hooks/setHeight'
import { colorList } from '../../styles/color'

const AccordionHead = () => {
  return (
    <div className="flex items-start space-x-2 py-6 pc:py-8 pl-4 pr-[56px] border-y-border10 border-solid border-y">
      <svg
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 6.67887C7 6.41441 7.2583 6.20001 7.57692 6.20001H21.4231C21.7417 6.20001 22 6.41441 22 6.67887V25.3545C22 25.619 21.7417 25.8333 21.4231 25.8333C21.1045 25.8333 20.8462 25.619 20.8462 25.3545V7.15774H8.15385V25.3545C8.15385 25.619 7.89555 25.8333 7.57692 25.8333C7.2583 25.8333 7 25.619 7 25.3545V6.67887Z"
          fill="white"
        />
        <path
          d="M17 11C17.5523 11 18 10.5523 18 10C18 9.44771 17.5523 9 17 9C16.4477 9 16 9.44771 16 10C16 10.5523 16.4477 11 17 11Z"
          fill="white"
        />
        <path
          d="M17 15.0001C17.5523 15.0001 18 14.5523 18 14.0001C18 13.4478 17.5523 13.0001 17 13.0001C16.4477 13.0001 16 13.4478 16 14.0001C16 14.5523 16.4477 15.0001 17 15.0001Z"
          fill="white"
        />
        <path
          d="M17 19.0001C17.5523 19.0001 18 18.5523 18 18.0001C18 17.4478 17.5523 17.0001 17 17.0001C16.4477 17.0001 16 17.4478 16 18.0001C16 18.5523 16.4477 19.0001 17 19.0001Z"
          fill="white"
        />
        <path
          d="M17 23C17.5523 23 18 22.5523 18 22C18 21.4477 17.5523 21 17 21C16.4477 21 16 21.4477 16 22C16 22.5523 16.4477 23 17 23Z"
          fill="white"
        />
        <path
          d="M12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11Z"
          fill="white"
        />
        <path
          d="M12 15.0001C12.5523 15.0001 13 14.5523 13 14.0001C13 13.4478 12.5523 13.0001 12 13.0001C11.4477 13.0001 11 13.4478 11 14.0001C11 14.5523 11.4477 15.0001 12 15.0001Z"
          fill="white"
        />
        <path
          d="M12 19.0001C12.5523 19.0001 13 18.5523 13 18.0001C13 17.4478 12.5523 17.0001 12 17.0001C11.4477 17.0001 11 17.4478 11 18.0001C11 18.5523 11.4477 19.0001 12 19.0001Z"
          fill="white"
        />
        <path
          d="M12 23C12.5523 23 13 22.5523 13 22C13 21.4477 12.5523 21 12 21C11.4477 21 11 21.4477 11 22C11 22.5523 11.4477 23 12 23Z"
          fill="white"
        />
      </svg>
      <p className="font-bold text-md leading-normal">拠点一覧</p>
    </div>
  )
}

const AccordionBody = (body: ReactNode) => {
  return <div className="mt-6">{body}</div>
}

const stylePlusMinus = css`
  position: relative;
  svg {
    path {
      fill: ${colorList.primary};
    }
  }
  &::before,
  &::after {
    background-color: ${colorList.primary};
    content: '';
    position: absolute;
  }
  &::before {
    //horizon
    height: 1px;
    right: 23px;
    top: calc(50%);
    width: 17px;
  }
  &::after {
    //vertical
    height: 17px;
    right: 31px;
    top: calc(50% - 8px);
    transition: transform 0.3s ease-out;
    width: 1px;
  }
  &:hover {
    svg {
      path {
        fill: ${colorList.white};
      }
    }
    &::before,
    &::after {
      background-color: white;
    }
  }
  &.isOpen {
    svg {
      path {
        fill: ${colorList.white};
      }
    }
    ::before,
    ::after {
      background-color: white;
    }
    ::after {
      transform: rotate(90deg);
    }
  }
`

interface Props {
  body: ReactNode
}

/**
 * アコーディオン拠点一覧
 * @param {ReactNode} body
 *  dlでラップして使用。
 *  isOpenがdtについたり消えたりします。
 */
/** */
export const AccordionOfficeList = ({ body }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleHandler = (e: React.MouseEvent<HTMLElement>) => {
    const _this: HTMLElement = e.currentTarget
    const nextElement = _this.nextElementSibling as HTMLElement
    isOpen ? setHeight0(nextElement) : setHeightAuto(nextElement)
    setIsOpen(!isOpen)
  }
  return (
    <>
      <dt
        onClick={toggleHandler}
        css={stylePlusMinus}
        className={
          `relative z-10 cursor-pointer duration-default hover:bg-primary hover:text-white` +
          (isOpen ? ' isOpen bg-primary text-white' : '')
        }
      >
        <AccordionHead />
      </dt>
      <dd
        className={
          `duration-default ease-out ` +
          (isOpen ? '' : `invisible h-0 -translate-y-[40px] opacity-0`)
        }
      >
        {AccordionBody(body)}
      </dd>
    </>
  )
}
