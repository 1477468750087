// 高さセットする関数
// アコーディオンで使用
export const setHeight0 = (element: HTMLElement) => {
  setTimeout(() => {
    element.style.height = '0'
  }, 300) //delayもたせてアニメーション
}
export const setHeightAuto = (element: HTMLElement) => {
  element.style.height = 'auto'
}
